export const ALL_BET_GAME_TYPES: { [index: number]: string } = {
    101: 'Normal Baccarat',
    111: 'VIP Baccarat',
    103: 'Quick Baccarat',
    104: 'See Card Baccarat',
    110: 'Insurance Baccarat',

    201: 'Sicbo(HiLo)',
    202: 'Fish Prawn Crab',

    301: 'Dragon Tiger',

    401: 'Roulette',

    501: 'Classic Pok Deng/Two Sides Pok Deng',

    601: 'Rock Paper Scissors',

    801: 'Bull Bull',

    901: 'Win Three Cards/Three Pictures',

    702: "Ultimate Texas Hold'em",

    602: 'Andar Bahar',

    603: 'Teen Patti 20-20',

    703: 'Casino War',

    704: 'Infinite Blackjack',
};
