const API_URL = 'https://api-fe.vibioz.com';
// const API_URL = 'http://localhost:9139';
const ALLBET_API_URL_GAME_LIST = '/allbet-getgamelist';
const ALLBET_API_URL_LOGIN_TRIAL = '/allbet-trial';
const ALLBET_API_GET_BALANCE = '/allbet-getbalance';
const ALLBET_CHECK_OR_CREATE_PLAYER = '/check-or-create-player';
const ALLBET_LOGIN_PLAYER = '/login-player';
const REQUEST_FS = '/request-fs';

class ApiHelper {
    public getAllBetGameList(callback?: (res: any) => void): void {
        this._apiRequest(`${API_URL}${ALLBET_API_URL_GAME_LIST}`, {}, callback);
    }

    public getAllBetGameLoginTrial(language: string, returnUrl: string, callback?: (res: any) => void): void {
        this._apiRequest(`${API_URL}${ALLBET_API_URL_LOGIN_TRIAL}`, { lang: language, ru: returnUrl }, callback);
    }

    public getBalance(player: string, callback?: (res: any) => void): void {
        this._apiRequest(`${API_URL}${ALLBET_API_GET_BALANCE}`, { player: player }, callback);
    }

    public requestRegisterPlayer(player: string, password?: string, callback?: (res: any) => void): void {
        const config = { player: player };
        if (password) {
            Object.assign(config, { password: password });
        }

        this._apiRequest(`${API_URL}${ALLBET_CHECK_OR_CREATE_PLAYER}`, config, callback);
    }

    public requestLoginPlayer(player: string, password: string, callback?: (res: any) => void): void {
        const config = { player: player, password: password };

        this._apiRequest(`${API_URL}${ALLBET_LOGIN_PLAYER}`, config, callback);
    }

    public requestFreeSpin(callback?: (res: any) => void): void {
        this._apiRequest(`${API_URL}${REQUEST_FS}`, {}, callback);
    }

    private _apiRequest(url: string, param?: { [key: string]: string }, callback?: (res: any) => void) {
        const xmlRequest = new XMLHttpRequest();

        if (param) {
            url += '?';
            const paramKey = Object.keys(param);
            paramKey.forEach((element, index) => {
                if (index !== 0) {
                    url += '&';
                }

                url += `${element}=${param[element]}`;
            });
        }

        xmlRequest.open('GET', url);

        xmlRequest.send();

        xmlRequest.onreadystatechange = () => {
            if (xmlRequest.readyState === XMLHttpRequest.DONE) {
                let result = xmlRequest.responseText ? xmlRequest.responseText : '';

                callback && callback(result);
            }
        };
    }
}

const apiHelper = new ApiHelper();
export { apiHelper };
