import { Component, CSSProperties } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import allbetImage from '../../image/logo/logo_allbet.png';
import xe88Image from '../../image/logo/logo_XE88.png';
import vivoGamingImage from '../../image/logo/logo_vivo_gaming.png';
import v8PokerImage from '../../image/logo/logo_v8_poker.png';
import redTigerNSexyBaccaratImage from '../../image/logo/logo_red_tiger_sexy_bacarat.png';
import pragmaticPlayImage from '../../image/logo/logo_pragmatic_play.png';
import playtechImage from '../../image/logo/logo-playtech.png';
import lucky365Image from '../../image/logo/logo_lucky365.png';
import live22Image from '../../image/logo/logo_live22.png';
import kmGamingImage from '../../image/logo/logo_km_gaming.png';
import geminiImage from '../../image/logo/logo_gemini.png';
import evoplayImage from '../../image/logo/logo_evoplay.png';
import evolutionImage from '../../image/logo/logo_evolution.png';
import dreamGamingImage from '../../image/logo/logo_dreamgaming.png';
import dragonSoftImage from '../../image/logo/logo_dragonsoft.png';
import btGamingImage from '../../image/logo/logo_bt_gaming.png';
import bigGamingImage from '../../image/logo/logo_big_gaming.png';
import bgSoftImage from '../../image/logo/logo_bg_soft.png';

interface ProviderProps {
    navigation?: NavigateFunction;
}

const style: { [key: string]: CSSProperties } = {
    providerContainer: {
        margin: '0.5em',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    buttonContainer: {
        margin: '1em',
        display: 'block',
        boxSizing: 'border-box',
        padding: '0',
        backgroundColor: 'rgba(0,0,0,0)',
        border: '0px solid',
    },
    imageContainer: {
        width: '10em',
        height: '10em',
        cursor: 'pointer',
    },
    imageLogo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
};

class ProviderComponent<T extends ProviderProps> extends Component<T> {
    public render() {
        const { navigation } = this.props;

        const toAllBetPage = () => {
            navigation!('/allbet');
        };

        const providerConfigList = [
            {
                id: 'allbet',
                title: 'ALLBET',
                onClick: toAllBetPage,
                image: allbetImage,
            },
            {
                id: 'xe88',
                title: 'XE88',
                onClick: toAllBetPage,
                image: xe88Image,
            },
            {
                id: 'vivo_gaming',
                title: 'VIVO GAMING',
                onClick: toAllBetPage,
                image: vivoGamingImage,
            },
            {
                id: 'v8_poker',
                title: 'V8 POKER',
                onClick: toAllBetPage,
                image: v8PokerImage,
            },
            {
                id: 'red_tiger_n_sexy_baccarat',
                title: 'RED TIGER', // too long for full name
                onClick: toAllBetPage,
                image: redTigerNSexyBaccaratImage,
            },
            {
                id: 'pragmatic_play',
                title: 'PRAGMATIC PLAY',
                onClick: toAllBetPage,
                image: pragmaticPlayImage,
            },
            {
                id: 'playtech',
                title: 'PLAYTECH',
                onClick: toAllBetPage,
                image: playtechImage,
            },
            {
                id: 'lucky365',
                title: 'LUCKY365',
                onClick: toAllBetPage,
                image: lucky365Image,
            },
            {
                id: 'live22',
                title: 'LIVE22',
                onClick: toAllBetPage,
                image: live22Image,
            },
            {
                id: 'km_gaming',
                title: 'KM GAMING',
                onClick: toAllBetPage,
                image: kmGamingImage,
            },
            {
                id: 'gemini',
                title: 'GEMINI',
                onClick: toAllBetPage,
                image: geminiImage,
            },
            {
                id: 'evoplay',
                title: 'EVOPLAY',
                onClick: toAllBetPage,
                image: evoplayImage,
            },
            {
                id: 'evolution',
                title: 'EVOLUTION',
                onClick: toAllBetPage,
                image: evolutionImage,
            },
            {
                id: 'dreamGaming',
                title: 'DREAM GAMING',
                onClick: toAllBetPage,
                image: dreamGamingImage,
            },
            {
                id: 'dragon_soft',
                title: 'DRAGON SOFT',
                onClick: toAllBetPage,
                image: dragonSoftImage,
            },
            {
                id: 'bt_gaming',
                title: 'BT GAMING',
                onClick: toAllBetPage,
                image: btGamingImage,
            },
            {
                id: 'big_gaming',
                title: 'BIG GAMING',
                onClick: toAllBetPage,
                image: bigGamingImage,
            },
            {
                id: 'bg_soft',
                title: 'BG SOFT', //changed name to T1 gaming
                onClick: toAllBetPage,
                image: bgSoftImage,
            },
        ];

        const providerButtonList = providerConfigList.map((element) => this._renderProviderButton(element.id, element.onClick, element.image, element.title));

        return (
            <div id="provider-container" style={style.providerContainer}>
                {providerButtonList}
            </div>
        );
    }

    private _renderProviderButton(id: string, onClick: () => void, image: string, title: string) {
        return (
            <button id={id} onClick={onClick} style={style.buttonContainer}>
                <div style={style.imageContainer}>
                    <img src={image} style={style.imageLogo} />
                </div>
                <div>{title}</div>
            </button>
        );
    }
}

export default (props: ProviderProps) => {
    const navigation = useNavigate();

    return <ProviderComponent {...props} navigation={navigation} />;
};
