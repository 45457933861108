import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeColor } from './Appearance';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

const rootElement = document.getElementById('root') as HTMLElement;
rootElement.style.width = '100%';
rootElement.style.height = '100%';

document.documentElement.style.setProperty('--background-color', ThemeColor.BackgroundColor);
document.documentElement.style.setProperty('--text-color', ThemeColor.TextColor);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
