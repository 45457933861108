import React from 'react';
import MainController from './page/MainController';
import TopNavComponent from './components/TopNavBar';

function App() {
    document.title = 'Megabox';

    return (
        <div className="App" style={{ height: '100%', width: '100%' }}>
            <TopNavComponent />
            <div id="page" style={{ height: '100%', width: '100%' }}>
                <main id="page-content">
                    <MainController />
                </main>
            </div>
        </div>
    );
}

export default App;
