import React, { Component, CSSProperties } from 'react';
import { apiHelper } from '../../../utils/ApiHelper';
import { ALL_BET_GAME_TYPES } from './AllBetGameConst';
import { ThemeColor } from '../../../Appearance';

interface AllBetGameProps {}

interface AllBetGameState {
    gameTable: { tableName: string; gameType: number }[];
}

const style: { [key: string]: CSSProperties } = {
    AllBetContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    CommonButton: {
        fontSize: '1rem',
        padding: '0.5rem 1.5rem',
        marginTop: '1rem',
        marginRight: '1rem',
        minWidth: '10rem',
        userSelect: 'none',
        borderRadius: '10px',
    },
    PlayButton: {
        background: `${ThemeColor.MainColor}`,
        border: '2px solid',
        borderColor: `${ThemeColor.MainColor}`,
        color: 'white',
        cursor: 'pointer',
    },
    DisableButton: {
        background: `${ThemeColor.MainColor}`,
        border: '2px solid',
        borderColor: `${ThemeColor.MainColor}`,
        color: 'grey',
        cursor: 'not-allowed',
    },
};

class AllBetGameContainer<T extends AllBetGameProps> extends Component<T, AllBetGameState> {
    constructor(props: T) {
        super(props);

        this.state = { gameTable: [] };
    }

    componentDidMount(): void {
        this._requestGameList();
    }

    public render() {
        const {} = this.props;
        const { gameTable } = this.state;

        return (
            <div id="allbet-container" style={style.AllBetContainer}>
                Game List :{this._renderGameList(gameTable)}
                {this._renderPlayButton()}
            </div>
        );
    }

    private _renderGameList(gameTable: { tableName: string; gameType: number }[]): JSX.Element {
        const gameElement = gameTable.map((element, index) => {
            const { tableName, gameType } = element;
            return (
                <div
                    id={`${gameType}-${index}`}
                    key={`${gameType}-${index}`}
                    // onClick={() => {
                    //     apiHelper.getAllBetGameLoginTrial('en', 'http://localhost:3000', (res) => {
                    //         const result = JSON.parse(res);

                    //         window.open(result.data.gameLoginUrl);
                    //     });
                    // }}
                >
                    {ALL_BET_GAME_TYPES[element.gameType]}
                </div>
            );
        });

        return <React.Fragment>{gameElement}</React.Fragment>;
    }

    private _renderPlayButton(): JSX.Element {
        const gameLoginUrl = localStorage.getItem('allbet-url');

        const enableButton = !!gameLoginUrl;

        const buttonStyle = { ...(enableButton ? style.PlayButton : style.DisableButton), ...style.CommonButton };

        return (
            <button
                id="PlayBtn"
                style={buttonStyle}
                disabled={!enableButton}
                onClick={() => {
                    if (gameLoginUrl) {
                        window.open(gameLoginUrl);
                    }
                }}
            >
                Play Now
            </button>
        );
    }

    private _requestGameList() {
        apiHelper.getAllBetGameList((res) => {
            const result: { tableName: string; gameType: number }[] = JSON.parse(res).response.data.tables;

            //do changes
            this.setState({ gameTable: result });
        });

        // apiHelper.getBalance('player1nev', (res) => {
        //     console.log(res);
        // });
    }
}

export default AllBetGameContainer;
