import { Component, CSSProperties } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import checkinImage from '../../image/promotion/checkin.png';
import freeSpinImage from '../../image/promotion/freespin.gif';
import rankImage from '../../image/promotion/rank.png';
import tipsImage from '../../image/promotion/tips.png';
import vipImage from '../../image/promotion/vip.png';
import { ThemeColor } from '../../Appearance';

interface PromotionProps {
    navigation?: NavigateFunction;
}

const style: { [key: string]: CSSProperties } = {
    promotionContainer: {
        width: '100px',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '.5em',
        paddingRight: '.5em',
    },
    imageContainer: {
        width: '5em',
        height: '5em',
        background: 'transparent',
    },
    imageLogo: {
        width: '100%',
        height: '100%',
    },
    buttonContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: `${ThemeColor.TextColor}`,
        background: `${ThemeColor.GradientBackgroundColor}`,
        borderRadius: '.5rem',
        fontSize: '.8rem',
        marginBottom: '.5em',
        cursor: 'pointer',
    },
};

class PromotionContainer<T extends PromotionProps> extends Component<T> {
    public render() {
        return (
            <div id="promotion-container" style={style.promotionContainer}>
                {this._renderPromotionButton(checkinImage, 'checkin', 'Daily Check In', 'checkin')}
                {this._renderPromotionButton(freeSpinImage, 'checkin', 'Free Spin', 'freespin')}
                {this._renderPromotionButton(tipsImage, 'checkin', 'Game Tips', 'tips')}
                {this._renderPromotionButton(rankImage, 'checkin', 'Ranking', 'rank')}
                {this._renderPromotionButton(vipImage, 'checkin', 'VIP', 'vip')}
            </div>
        );
    }

    private _renderPromotionButton(imgSrc: string, id: string, title: string, navigatePath: string) {
        const { navigation } = this.props;

        return (
            <div id={id} style={style.buttonContainer} onClick={() => navigation!(navigatePath)}>
                <div style={style.imageContainer}>
                    <img src={imgSrc} alt={id} style={style.imageLogo} />
                </div>
                <p>{title}</p>
            </div>
        );
    }
}

export default (props: PromotionProps) => {
    const navigation = useNavigate();

    return <PromotionContainer {...props} navigation={navigation} />;
};
