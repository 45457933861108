import React, { Component, CSSProperties } from 'react';
import InformationContainer from './InformationContainer';
import SlidingContainer from './SlidingContainer';
import { SlideItem } from './ImageSlider';
import LoginComponent from './LoginComponent';
import ProviderContainer from './ProviderContainer';
import PromotionContainer from './PromotionContainer';
import banner1Img from '../../image/home/banner_1.png';
import banner2Img from '../../image/home/banner_2.png';
import banner3Img from '../../image/home/banner_3.png';

interface HomeProps {
    currentLocale: string;
    player?: string;
    onLogout?: () => void;
}

interface HomeState {
    promotionItem: SlideItem[];
}

const style: { [key: string]: CSSProperties } = {
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
};

class Home<T extends HomeProps> extends Component<T, HomeState> {
    constructor(props: T) {
        super(props);

        this.state = { promotionItem: [] };
    }

    componentDidMount(): void {
        this._requestPromotionItem();
    }

    public render() {
        const { currentLocale, player, onLogout } = this.props;

        const { promotionItem } = this.state;

        return (
            <div id="home_page_container">
                <InformationContainer currentLocale={currentLocale} />
                <SlidingContainer slideItem={promotionItem} />
                <LoginComponent player={player} onLogout={onLogout} />
                <div id="content_container" style={style.contentContainer}>
                    <PromotionContainer />
                    <ProviderContainer />
                </div>
            </div>
        );
    }

    private _requestPromotionItem() {
        //ToDo : request api from back office to get img src uploaded by operator

        //mock currently
        this.setState({
            promotionItem: [
                {
                    id: 1,
                    imageSrc: banner1Img,
                    title: 'Title A',
                },
                {
                    id: 2,
                    imageSrc: banner2Img,
                    title: 'Title B',
                },
                {
                    id: 3,
                    imageSrc: banner3Img,
                    title: 'Title C',
                },
            ],
        });
    }
}

export default Home;
