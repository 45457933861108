import { Component, CSSProperties } from 'react';
import styled, { keyframes } from 'styled-components';
import { ThemeColor } from '../../Appearance';

const scrollLeft = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

interface InformationProps {
    currentLocale: string;
}

const localeIcon: { [key: string]: string } = {
    en: `data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20id='flag-icons-gb'%20viewBox='0%200%20512%20512'%3e%3cpath%20fill='%23012169'%20d='M0%200h512v512H0z'/%3e%3cpath%20fill='%23FFF'%20d='M512%200v64L322%20256l190%20187v69h-67L254%20324%2068%20512H0v-68l186-187L0%2074V0h62l192%20188L440%200z'/%3e%3cpath%20fill='%23C8102E'%20d='m184%20324%2011%2034L42%20512H0v-3zm124-12%2054%208%20150%20147v45zM512%200%20320%20196l-4-44L466%200zM0%201l193%20189-59-8L0%2049z'/%3e%3cpath%20fill='%23FFF'%20d='M176%200v512h160V0zM0%20176v160h512V176z'/%3e%3cpath%20fill='%23C8102E'%20d='M0%20208v96h512v-96zM208%200v512h96V0z'/%3e%3c/svg%3e`,
    cn: `data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20id='flag-icons-cn'%20viewBox='0%200%20512%20512'><defs><path%20id='cn-a'%20fill='%23ff0'%20d='M1-.3-.7.8%200-1%20.6.8-1-.3z'/></defs><path%20fill='%23ee1c25'%20d='M0%200h512v512H0z'/><use%20xlink:href='%23cn-a'%20width='30'%20height='20'%20transform='translate(128%20128)scale(76.8)'/><use%20xlink:href='%23cn-a'%20width='30'%20height='20'%20transform='rotate(-121%20142.6%20-47)scale(25.5827)'/><use%20xlink:href='%23cn-a'%20width='30'%20height='20'%20transform='rotate(-98.1%20198%20-82)scale(25.6)'/><use%20xlink:href='%23cn-a'%20width='30'%20height='20'%20transform='rotate(-74%20272.4%20-114)scale(25.6137)'/><use%20xlink:href='%23cn-a'%20width='30'%20height='20'%20transform='matrix(16%20-19.968%2019.968%2016%20256%20230.4)'/></svg>`,
    my: `data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20id='flag-icons-my'%20viewBox='0%200%20512%20512'><path%20fill='%23C00'%20d='M0%200h512v36.6H0z'/><path%20fill='%23fff'%20d='M0%2036.6h512V73H0z'/><path%20fill='%23C00'%20d='M0%2073.1h512v36.6H0z'/><path%20fill='%23fff'%20d='M0%20109.7h512v36.6H0z'/><path%20fill='%23C00'%20d='M0%20146.3h512v36.6H0z'/><path%20fill='%23fff'%20d='M0%20182.9h512v36.5H0z'/><path%20fill='%23C00'%20d='M0%20219.4h512V256H0z'/><path%20fill='%23fff'%20d='M0%20256h512v36.6H0z'/><path%20fill='%23C00'%20d='M0%20292.6h512V329H0z'/><path%20fill='%23fff'%20d='M0%20329.1h512v36.6H0z'/><path%20fill='%23C00'%20d='M0%20365.7h512v36.6H0z'/><path%20fill='%23fff'%20d='M0%20402.3h512v36.6H0z'/><path%20fill='%23C00'%20d='M0%20438.9h512v36.5H0z'/><path%20fill='%23fff'%20d='M0%20475.4h512V512H0z'/><path%20fill='%23006'%20d='M0%200h256v292.6H0z'/><path%20fill='%23FC0'%20d='m166%2093%204.8%2032.5%2018.4-27.2-10%2031.3%2028.5-16.6-22.5%2024%2032.8-2.6-30.7%2011.9L218%20158l-32.8-2.5%2022.5%2024-28.4-16.7%209.8%2031.5-18.4-27.3-4.8%2032.5-4.7-32.5-18.4%2027.2%209.9-31.4-28.4%2016.7%2022.4-24-32.8%202.5%2030.7-11.8-30.6-11.9%2032.8%202.6-22.5-24%2028.4%2016.6-10-31.4%2018.5%2027.3%204.8-32.6Zm-26.7%201.3a56.9%2056.9%200%200%200-73%2024.9%2056.9%2056.9%200%200%200%2045.5%2083.8%2056.9%2056.9%200%200%200%2027.5-4.7%2064%2064%200%201%201%200-104'/></svg>`,
};

const style: { [key: string]: CSSProperties } = {
    informationContainer: {
        justifyContent: 'space-between',
        display: 'flex',
        marginTop: '.25rem',
        marginBottom: '.25rem',
        marginLeft: '.5rem',
        marginRight: '.5rem',
    },

    announcementContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
        borderRadius: '.375rem',
        backgroundColor: 'rgb(209,213,219)',
    },
    announcementLogo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    announcementMessageContainer: {
        backgroundColor: 'transparent',
        overflow: 'hidden',
    },

    announcementMessage: {
        backgroundColor: 'transparent',
        color: 'black',
        width: '100000px',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },

    notificationContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '8%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    notificationButton: {
        margin: '0',
        display: 'block',
        boxSizing: 'border-box',
        padding: '0',
        backgroundColor: 'rgba(0,0,0,0)',
        border: '0px solid',
        cursor: 'pointer',
    },

    localizationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        tabSize: '4',
    },
    localizationButton: {
        backgroundImage: `none`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% center',
        height: '1.5rem',
        width: '1.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0)',
        padding: '0',
        cursor: 'pointer',
        border: '0px solid',
        borderRadius: '99999px',
    },
};

const ScrollingText = styled.span`
    animation: ${scrollLeft} 98.6s linear infinite;
`;

class InformationContainer<T extends InformationProps> extends Component<T> {
    public render() {
        return (
            <div id="information_container" style={style.informationContainer}>
                {this._renderAnnouncementContainer()}
                <div id="notification_container">{this._renderNotificationContainer()}</div>
                <div id="localization_container">{this._renderLocalizationContainer()}</div>
            </div>
        );
    }

    private _renderAnnouncementContainer(): JSX.Element {
        const announcementLogo = (
            <div id="announcement_logo" style={style.announcementLogo}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.0532 1.26843C11.0532 0.906029 11.174 0.604029 11.4156 0.362429C11.6572 0.120829 11.9592 2.86102e-05 12.3216 2.86102e-05C12.684 2.86102e-05 12.986 0.120829 13.2276 0.362429C13.4692 0.604029 13.59 0.906029 13.59 1.26843V10.6002C13.59 10.9626 13.4692 11.2646 13.2276 11.5062C12.986 11.7478 12.684 11.8686 12.3216 11.8686C11.9592 11.8686 11.6572 11.7478 11.4156 11.5062C11.174 11.2646 11.0532 10.9626 11.0532 10.6002V10.419C9.3922 9.45263 7.4292 8.86373 5.1642 8.65233L5.5266 10.9626C5.5568 11.2344 5.48885 11.4685 5.32275 11.6648C5.15665 11.8611 4.9377 11.9592 4.6659 11.9592H4.2129C4.0317 11.9592 3.87315 11.9139 3.73725 11.8233C3.60135 11.7327 3.5032 11.6119 3.4428 11.4609L1.8573 8.47113H1.6761C1.2231 8.47113 0.8305 8.30503 0.4983 7.97283C0.1661 7.64063 0 7.24803 0 6.79503V5.07363C0 4.62063 0.1661 4.22803 0.4983 3.89583C0.8305 3.56363 1.2231 3.39753 1.6761 3.39753C2.4009 3.36733 3.1106 3.33713 3.8052 3.30693C5.1642 3.24653 6.4477 3.06533 7.6557 2.76333C8.9241 2.43113 10.0566 1.99323 11.0532 1.44963V1.26843ZM11.9139 1.26843V10.6002C11.9139 10.721 11.9516 10.8192 12.0272 10.8947C12.1026 10.9702 12.2008 11.0079 12.3216 11.0079C12.4424 11.0079 12.5406 10.9702 12.616 10.8947C12.6916 10.8192 12.7293 10.721 12.7293 10.6002V1.26843C12.7293 1.14763 12.6916 1.04193 12.616 0.951328C12.5406 0.860728 12.4424 0.815429 12.3216 0.815429C12.2008 0.815429 12.0951 0.860728 12.0045 0.951328C11.9139 1.04193 11.8686 1.14763 11.8686 1.26843H11.9139ZM11.0532 2.40093C10.0868 2.91433 8.9996 3.30693 7.7916 3.57873C6.6742 3.85053 5.4964 4.03173 4.2582 4.12233V7.74633H4.7112C7.1272 7.92753 9.2412 8.50133 11.0532 9.46773V2.40093ZM3.3975 7.70103V4.16763L1.7214 4.21293C1.4798 4.21293 1.27595 4.29598 1.10985 4.46208C0.94375 4.62818 0.8607 4.83203 0.8607 5.07363V6.79503C0.8607 7.00643 0.94375 7.20273 1.10985 7.38393C1.27595 7.56513 1.4798 7.65573 1.7214 7.65573C2.265 7.65573 2.8237 7.67083 3.3975 7.70103ZM2.8539 8.51643L4.2129 11.0985H4.6659L4.3035 8.60703L2.8539 8.51643Z"
                        fill="#191919"
                    ></path>
                </svg>
            </div>
        );

        const announcementMessage = (
            <div id="announcement_message" style={style.announcementMessageContainer}>
                <div style={style.announcementMessage}>
                    <ScrollingText>
                        📣IMANTAP88 | Bet For Win Safe | 📣Malaysia Online Casino Terbaik 📣New game launching 🥇JILI 🥈MG | MicroGaming| Come try our new game, new luck for
                        you ! 🎮Download our IMANTAP88 apps IOS & Android, Easy, Fast, Safe!. 🏆We highly recommend deposit using ''AUTO Ewallet'' [TouchnGo, Boostpay,
                        Grabpay, ShopeePay, Duitnow, Visa card, Master card, Credit card]. NO SCAM, NO WAITING, NO SCAN QR. 🎁🎁🎁 Minat Untung Extra Wang? Ajak Kawan Anda
                        Join Server Kami, Dapat 10% Extra Bonus Wang😘😘😘
                    </ScrollingText>
                </div>
            </div>
        );

        return (
            <div id="announcement_container" style={style.announcementContainer}>
                {announcementLogo}
                {announcementMessage}
            </div>
        );
    }

    private _renderNotificationContainer(): JSX.Element {
        return (
            <div style={style.notificationContainer}>
                <button style={style.notificationButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="24" height="24" viewBox="0 0 24 24">
                        <path
                            fill={ThemeColor.MainColor}
                            d="M5.5 18.77q-.213 0-.356-.145T5 18.268t.144-.356t.356-.143h1.116V9.846q0-1.96 1.24-3.447T11 4.546V4q0-.417.291-.708q.291-.292.707-.292t.709.292T13 4v.546q1.904.365 3.144 1.853t1.24 3.447v7.923H18.5q.213 0 .356.144q.144.144.144.357t-.144.356t-.356.143zm6.497 2.615q-.668 0-1.14-.475t-.472-1.14h3.23q0 .67-.475 1.142q-.476.472-1.143.472M7.616 17.77h8.769V9.846q0-1.823-1.281-3.104T12 5.462t-3.104 1.28t-1.28 3.104z"
                        ></path>
                    </svg>
                </button>
            </div>
        );
    }

    private _renderLocalizationContainer(): JSX.Element {
        const { currentLocale } = this.props;
        return (
            <div style={style.localizationContainer}>
                <button
                    style={{
                        ...style.localizationButton,
                        backgroundImage: `url("${localeIcon[currentLocale]}")`,
                    }}
                ></button>
            </div>
        );
    }
}

export default InformationContainer;
