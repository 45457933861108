import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';

export interface SlideItem {
    id: number;
    imageSrc: string;
    title: string;
}

interface SlideProps {
    items: SlideItem[];
}

const ImageSlider: React.FC<SlideProps> = ({ items }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 30000000,
        draggable: true,
    };

    const imageStyle: React.CSSProperties = {
        maxWidth: '98%',
        maxHeight: '550px',
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        borderRadius: '20px',
    };

    const bannerContainerStyle: React.CSSProperties = {
        display: 'flex',
        width: '100%',
    };

    const bannerInformationStyle: React.CSSProperties = {
        position: 'absolute',
        marginLeft: '7%',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const bannerTitleStyle: React.CSSProperties = {
        fontWeight: '700',
        fontSize: '4vw',
    };

    return (
        <Slider {...settings}>
            {items.map((item) => (
                <div key={item.id}>
                    <div id="banner-container" style={bannerContainerStyle}>
                        <img src={item.imageSrc} alt={item.title} style={imageStyle} />
                        {/* <div id="banner-information" style={bannerInformationStyle}>
                            <div id="banner-title" style={bannerTitleStyle}>
                                {item.title}
                            </div>
                        </div> */}
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default ImageSlider;
