// LoadingOverlay.tsx
import React from 'react';
import './LoadingOverlay.css'; // Create this CSS file for styling

interface LoadingOverlayProps {
    show: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ show }) => {
    if (!show) return null; // Don't render if not showing

    return (
        <div className="loading-overlay">
            <div className="spinner"></div>
        </div>
    );
};

export default LoadingOverlay;
