import { Component, CSSProperties } from "react";
import ImageSlider, { SlideItem } from "./ImageSlider";

interface HomeProps {
  slideItem: SlideItem[];
}

const style: { [key: string]: CSSProperties } = {
  SlidingContainer: {},
};

class SlidingContainer<T extends HomeProps> extends Component<T> {
  public render() {
    const { slideItem } = this.props;

    return (
      <div id="sliding-container" style={style.SlidingContainer}>
        <ImageSlider items={slideItem} />
      </div>
    );
  }
}

export default SlidingContainer;
