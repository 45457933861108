import { Component, CSSProperties } from 'react';
import titleImage from '../../src/image/title.png';
import { ThemeColor } from '../Appearance';

interface TopNavComponentProps {}

const style: { [key: string]: CSSProperties } = {
    topNavRootContainer: {
        padding: '0.5rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        backgroundClip: 'border-box',
    },
    topNavContainer: {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'grid',
        border: '0px solid rgb(229,231,235)',
        gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
    },
    strokeButton: {
        margin: '0',
        display: 'block',
        boxSizing: 'border-box',
        padding: '0',
        backgroundColor: 'rgba(0,0,0,0)',
        border: '0px solid',
        cursor: 'pointer',
    },
    stroke: {
        stroke: 'currentcolor',
        width: '1.5rem',
        height: '1.5rem',
        display: 'inline-block',
        verticalAlign: 'middle',
        color: `${ThemeColor.MainColor}`,
    },
    logoContainer: {
        placeSelf: 'center',
    },
    logoButton: {
        backgroundColor: 'rgba(0,0,0,0)',
        border: '0px solid',
        cursor: 'pointer',
    },
    logo: {
        maxHeight: '3rem',
        maxWidth: '100%',
        height: 'auto',
        verticalAlign: 'middle',
        cursor: 'pointer',
    },
};

class TopNavComponent<T extends TopNavComponentProps> extends Component<T> {
    public render() {
        return (
            <div style={style.topNavRootContainer}>
                <div style={style.topNavContainer}>
                    {this.renderNavButton()}
                    {this._renderLogo()}{' '}
                    <div>
                        <div> </div>
                    </div>
                </div>{' '}
            </div>
        );
    }

    private renderNavButton(): JSX.Element {
        return (
            <div>
                <button style={style.strokeButton}>
                    <svg style={style.stroke} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
        );
    }

    private _renderLogo(): JSX.Element {
        return (
            <div style={style.logoContainer}>
                <button
                    style={style.logoButton}
                    onClick={() => {
                        //TODO - refine using react method
                        document.location.href = '/';
                    }}
                >
                    <img style={style.logo} src={`${titleImage}`} alt="Logo" />
                </button>
            </div>
        );
    }
}

export default TopNavComponent;
