import React, { Component, CSSProperties } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ThemeColor } from '../../Appearance';

interface LoginProps {
    navigation?: NavigateFunction;
    player?: string;
    onLogout?: () => void;
}

const style: { [key: string]: CSSProperties } = {
    LoginContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    CommonButton: {
        fontSize: '1rem',
        padding: '0.5rem 1.5rem',
        marginRight: '1rem',
        minWidth: '10rem',
        userSelect: 'none',
        cursor: 'pointer',
    },
    LoginButton: {
        background: `${ThemeColor.GradientBackgroundColor}`,
        border: '2px solid',
        borderColor: `${ThemeColor.MainColor}`,
        color: `${ThemeColor.TextColor}`,
        borderRadius: '10px',
    },
    RegisterButton: {
        background: `${ThemeColor.MainColor}`,
        border: '2px solid',
        borderColor: `${ThemeColor.MainColor}`,
        color: 'white',
        borderRadius: '10px',
    },
    LogoutContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
};

class LoginComponent<T extends LoginProps> extends Component<T> {
    public render() {
        const { player } = this.props;

        return (
            <div id="login-container" style={style.LoginContainer}>
                {!player && this._renderLoginButton()}
                {!player && this._renderRegisterButton()}
                {player && this._renderLogoutComponent(player)}
            </div>
        );
    }

    private _renderLoginButton() {
        const loginText = 'Login';

        const toLoginPage = () => {
            // changePage('/login');
            this.props.navigation!('/login');
        };

        return (
            <button id="LoginBtn" style={{ ...style.LoginButton, ...style.CommonButton }} onClick={toLoginPage}>
                {loginText}
            </button>
        );
    }

    private _renderRegisterButton() {
        const registerText = 'Register';

        const toRegisterPage = () => {
            this.props.navigation!('/register');
        };

        return (
            <button id="RegisterBtn" style={{ ...style.RegisterButton, ...style.CommonButton }} onClick={toRegisterPage}>
                {registerText}
            </button>
        );
    }

    private _renderLogoutComponent(playerName: string) {
        const logoutText = 'Logout';

        const logOut = () => {
            // this.props.navigation!('/');
            this.props.onLogout!();
        };

        return (
            <div id="logout-container" style={style.LogoutContainer}>
                <div style={{ marginRight: '1em' }}>{playerName}</div>
                <button id="RegisterBtn" style={{ ...style.LoginButton, ...style.CommonButton }} onClick={logOut}>
                    {logoutText}
                </button>
            </div>
        );
    }
}

export default (props: LoginProps) => {
    const navigation = useNavigate();

    return <LoginComponent {...props} navigation={navigation} />;
};
