import { Component } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import { apiHelper } from '../../utils/ApiHelper';
import LoadingOverlay from '../../components/LoadingBlocker';
import { ThemeColor } from '../../Appearance';

interface LoginProps {
    currentLocale: string;
    navigation?: NavigateFunction;
    onLogIn: (data: any) => void;
}

interface LoginState {
    username: string;
    password: string;
    error: string;
    showLoading: boolean;
}

const style: { [key: string]: CSSProperties } = {
    registerContainer: {
        width: '300px',
        margin: '100px auto',
        padding: '30px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    inputField: {
        width: '100%',
        padding: '8px 0',
        marginBottom: '10px',
    },
    submitButton: {
        width: '100%',
        padding: '10px',
        marginTop: '10px',
        backgroundColor: `${ThemeColor.MainColor}`,
        color: 'white',
        border: 'none',
        borderRadius: '5px',
    },
};

class Login<T extends LoginProps> extends Component<T, LoginState> {
    constructor(props: T) {
        super(props);

        this.state = { username: '', password: '', error: '', showLoading: false };
    }

    componentDidMount(): void {}

    public render() {
        const { username, password, error, showLoading } = this.state;

        const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            this.setState({ showLoading: true });
            this._loginPlayer();
        };

        return (
            <div style={style.registerContainer}>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input type="text" value={username} onChange={(e) => this.setState({ username: e.target.value })} required style={style.inputField} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) => this.setState({ password: e.target.value })} required style={style.inputField} />
                    </div>

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit" style={style.submitButton}>
                        Login
                    </button>
                </form>
                <LoadingOverlay show={showLoading} />
            </div>
        );
    }

    private _loginPlayer(): void {
        const { navigation } = this.props;
        const { username, password } = this.state;

        apiHelper.requestLoginPlayer(username, password, (res: any) => {
            const response = JSON.parse(res);
            this.setState({ showLoading: false });

            console.log(res);

            if (response) {
                if (response.error) {
                    this.setState({ error: `${response.error.message}` });
                } else if (response.response && response.status && response.status === 1) {
                    this.setState({ error: `` });

                    this.props.onLogIn({ ...response.response.data, player: username });
                    navigation!('/');
                } else {
                    this.setState({ error: `server error` });
                }
            } else {
                this.setState({ error: `server error` });
            }
        });
    }
}

export default (props: LoginProps) => {
    const navigation = useNavigate();

    return <Login {...props} navigation={navigation} />;
};
