import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login/Login';
import Home from './Home/Home';
import AllBetPage from './AllBet/AllBetPage';
import FreeSpinPage from './Freespin/FreeSpinPage';
import RegisterPage from './Register/RegisterPage';

const MainController: React.FC = () => {
    const [locale, setLocale] = useState('en');
    const [player, setPlayer] = useState<string | undefined>(undefined);

    const playerLocalStorage = localStorage.getItem('player');
    if (playerLocalStorage && player === undefined) {
        setPlayer(playerLocalStorage);
    }

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Home
                            currentLocale={locale}
                            player={player}
                            onLogout={() => {
                                //logout
                                localStorage.removeItem('player');
                                localStorage.removeItem('allbet-url');
                                setPlayer(undefined);
                            }}
                        />
                    }
                />
                <Route
                    path="/login"
                    element={
                        <Login
                            currentLocale={locale}
                            onLogIn={(result) => {
                                localStorage.setItem('player', result.player);
                                localStorage.setItem('allbet-url', result.gameLoginUrl);
                                setPlayer(result.player);
                            }}
                        />
                    }
                />
                <Route path="/register" element={<RegisterPage currentLocale={locale} />} />
                <Route path="/allbet" element={<AllBetPage currentLocale={locale} />} />
                <Route path="/freespin" element={<FreeSpinPage currentLocale={locale} />} />
            </Routes>
        </Router>
    );
};

export default MainController;
