// Roulette.tsx
import React, { Component } from 'react';
import rouletteImg from '../../image/freespin/roulette.png';
import rouletteBorderImg from '../../image/freespin/roulette-border.png';
import { CSSProperties } from 'styled-components';
import { apiHelper } from '../../utils/ApiHelper';

interface RouletteProps {
    items: string[]; // Array of sector names or values
}

interface RouletteState {
    canSpin: boolean;
    rotation: number;
    spinning: boolean;
    showResult: boolean;
    resultIndex: number;
}
const SPIN_DURATION = 3000;
const TOTAL_SPINS = 4;

const style: { [key: string]: CSSProperties } = {
    rouletteContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '350px',
    },
    rouletteImgContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    },
    rouletteImgBoardContainer: {
        position: 'absolute',
        width: '80%',
    },
    rouletteImg: {
        // position: 'absolute',
        width: '100%',
    },
    rouletteBorderImg: {
        position: 'absolute',
        width: '90%',
    },
    rouletteValue: {
        position: 'absolute',
        fontSize: '1.5em',
        height: '40px',
        width: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transformOrigin: 'top left',
    },
    spinButton: {
        color: 'black',
        backgroundColor: 'rgb(255,216,0)',
        borderRadius: '99999px',
        border: 'none',
        padding: '10px 50px',
        fontWeight: 'bolder',
        fontSize: '24px',
        cursor: 'pointer',
    },
    spinButtonDisabled: {
        color: 'grey',
        backgroundColor: 'rgb(247,224,98)',
        cursor: 'not-allowed',
    },
};

const rouletteValueStyle: CSSProperties[] = [
    {
        //0
        marginLeft: '57%',
        transform: 'rotate(90deg)',
        top: '15px',
    },
    {
        //1
        marginLeft: '24%',
        transform: 'rotate(47deg)',
        top: '35px',
    },
    {
        //2
        marginLeft: '8%',
        transform: 'rotate(11deg)',
        top: '100px',
        color: 'black',
    },
    {
        //3
        marginLeft: '6%',
        transform: 'rotate(-26deg)',
        top: '185px',
    },
    {
        //4
        marginLeft: '20%',
        transform: 'rotate(-58deg)',
        top: '259px',
    },
    {
        //5
        marginLeft: '48%',
        transform: 'rotate(263deg)',
        top: '287px',
        color: 'black',
    },
    {
        //6
        marginLeft: '76%',
        transform: 'rotate(-132deg)',
        top: '264px',
    },
    {
        //7
        marginLeft: '95%',
        transform: 'rotate(-168deg)',
        top: '197px',
        color: 'black',
    },
    {
        //8
        marginLeft: '97%',
        transform: 'rotate(-208deg)',
        top: '106px',
    },
    {
        //9
        marginLeft: '85%',
        transform: 'rotate(130deg)',
        top: '52px',
        color: 'black',
    },
];

class Roulette<T extends RouletteProps> extends Component<T, RouletteState> {
    constructor(props: T) {
        super(props);

        this.state = { canSpin: true, spinning: false, rotation: 0, showResult: false, resultIndex: 1 };
    }

    public render() {
        const rouletteList = [12.8, 3, 0.5, 288, 30, 2, 188, 0.88, 18.88, 1.2];
        const { rotation, resultIndex } = this.state;

        const rouletteValueElement = rouletteList.map((element, index) => {
            return this._renderRouletteValue(index, element.toFixed(2));
        });

        return (
            <React.Fragment>
                <div id="roulette-container" style={style.rouletteContainer}>
                    <div style={style.rouletteImgContainer}>
                        <div id="roulette-board-container" style={Object.assign({ ...style.rouletteImgBoardContainer }, { transform: `rotate(${rotation}deg)` })}>
                            <img src={rouletteImg} alt="roulette-img" style={style.rouletteImg} />
                            {rouletteValueElement}
                        </div>
                        <img src={rouletteBorderImg} alt="roulette-border" style={style.rouletteBorderImg} />
                    </div>
                </div>
                <div id="interactable-container">{this._renderSpinButton()}</div>
                {this._showWinResult(rouletteList[resultIndex])}
            </React.Fragment>
        );
    }

    private _renderSpinButton() {
        const { canSpin } = this.state;
        let buttonStyle = style.spinButton;
        if (!canSpin) {
            buttonStyle = Object.assign({ ...buttonStyle }, { ...style.spinButtonDisabled });
        }

        return (
            <button style={buttonStyle} onClick={this._onSpinButtonClick.bind(this)} disabled={!canSpin}>
                Spin Now
            </button>
        );
    }
    private _showWinResult(resultValue: number) {
        const { showResult } = this.state;

        if (showResult) {
            return <div>{`You Won ${resultValue.toFixed(2)}`}</div>;
        }
    }

    private _onSpinButtonClick(): void {
        this.setState({ canSpin: false });

        apiHelper.requestFreeSpin((resultText) => {
            const result = JSON.parse(resultText);

            if (result.error) {
                //handle error
                return;
            }

            const spinResult = result.result;

            this.setState({ spinning: true, resultIndex: spinResult });
            const desiredAngle = TOTAL_SPINS * 360 + spinResult * 33 + 33 / 2;

            this._animateSpin(desiredAngle);
        });
    }

    private _animateSpin(desireAngle: number): void {
        let start = 0;

        const { rotation } = this.state;
        const startRotation = rotation;

        const step = (timestamp: number) => {
            if (!start) start = timestamp;

            const elapsed = timestamp - start;

            const progress = Math.min(elapsed / SPIN_DURATION, 1); // Progress from 0 to 1
            const easing = this.easeOutQuad(progress); // Apply easing function for deceleration
            const currentRotation = startRotation + easing * (desireAngle - startRotation);

            this.setState({ rotation: currentRotation }); // Update the rotation state

            if (progress < 1) {
                requestAnimationFrame(step); // Continue animation until progress reaches 1
            } else {
                this.setState({ spinning: false, showResult: true });
            }
        };

        requestAnimationFrame(step);
    }

    private easeOutQuad(t: number) {
        return t * (2 - t);
    }

    private _renderRouletteValue(index: number, value: string) {
        return (
            <div id={`roulette-${index}`} style={{ ...style.rouletteValue, ...rouletteValueStyle[index] }}>
                {value}
            </div>
        );
    }
}

export default Roulette;
