import { Component } from 'react';
import { CSSProperties } from 'styled-components';
import AllBetGameContainer from '../Home/AllBetGame/AllBetGameContainer';

interface AllBetPageProps {
    currentLocale: string;
}

const style: { [key: string]: CSSProperties } = {};

class AllBetPage<T extends AllBetPageProps> extends Component<T> {
    constructor(props: T) {
        super(props);
    }

    componentDidMount(): void {}

    public render() {
        return <AllBetGameContainer></AllBetGameContainer>;
    }
}

export default AllBetPage;
