import { Component } from 'react';
import { CSSProperties } from 'styled-components';
import freespinBackgroundImage from '../../image/freespin/bg-freespin.jpeg';
import freeSpinTextImage from '../../image/freespin/text-freespin.png';
import Roulette from './Roulette';

interface FreeSpinPageProps {
    currentLocale: string;
}

const style: { [key: string]: CSSProperties } = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    freespinContainer: {
        backgroundImage: `url(${freespinBackgroundImage})`,
        backgroundPosition: '50% 50%',
        position: 'absolute',
        maxHeight: '1100px',
        maxWidth: '700px',
        height: '600px',
        width: '350px',
        border: '5px solid rgb(255,153,5)',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
    },
    creditWonContainer: {
        color: 'white',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    creditValue: {
        marginLeft: '5px',
        backgroundColor: 'rgb(255,200,5)',
        color: 'black',
        padding: '0 5px',
        borderRadius: '8px',
    },
    freeSpinTextContainer: {
        marginTop: '5%',
        width: '60%',
        height: 'auto',
    },
    freeSpinText: {
        width: '100%',
        height: '100%',
    },
};

class FreeSpinPage<T extends FreeSpinPageProps> extends Component<T> {
    constructor(props: T) {
        super(props);
    }

    componentDidMount(): void {}

    public render() {
        const creditWon: number = 0;

        // Sample items and predetermined result index
        const items = ['1.00', '3.00', '10.00', '20.00', '30.00', '100.00'];
        const predeterminedIndex = 3; // Index of the desired result (e.g., 20.00)

        return (
            <div style={style.container}>
                <div id="freespinContainer" style={style.freespinContainer}>
                    <div style={style.creditWonContainer}>
                        Credit Won : <span style={style.creditValue}>{creditWon.toFixed(2)}</span>
                    </div>
                    <div style={style.freeSpinTextContainer}>
                        <img src={freeSpinTextImage} alt={'freespin-text'} style={style.freeSpinText} />
                    </div>
                    <Roulette items={items} resultIndex={predeterminedIndex} />
                </div>
            </div>
        );
    }
}

export default FreeSpinPage;
