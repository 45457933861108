import { Component } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import { apiHelper } from '../../utils/ApiHelper';
import LoadingOverlay from '../../components/LoadingBlocker';
import { ThemeColor } from '../../Appearance';

interface RegisterProps {
    currentLocale: string;
    navigation?: NavigateFunction;
}

interface RegisterState {
    username: string;
    password: string;
    confirmPassword: string;
    error: string;
    showLoading: boolean;
}

const style: { [key: string]: CSSProperties } = {
    registerContainer: {
        width: '300px',
        margin: '100px auto',
        padding: '30px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    inputField: {
        width: '100%',
        padding: '8px 0',
        marginBottom: '10px',
    },
    submitButton: {
        width: '100%',
        padding: '10px',
        marginTop: '10px',
        backgroundColor: `${ThemeColor.MainColor}`,
        color: 'white',
        border: 'none',
        borderRadius: '5px',
    },
};

class RegisterPage<T extends RegisterProps> extends Component<T, RegisterState> {
    constructor(props: T) {
        super(props);

        this.state = { username: '', password: '', confirmPassword: '', error: '', showLoading: false };
    }

    componentDidMount(): void {}

    public render() {
        const { username, password, confirmPassword, error, showLoading } = this.state;

        const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (password !== confirmPassword) {
                this.setState({ error: 'password not match' });
            } else {
                this.setState({ showLoading: true });
                this._registerPlayer();
            }
        };

        return (
            <div style={style.registerContainer}>
                <h2>Register</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Username:</label>
                        <input type="text" value={username} onChange={(e) => this.setState({ username: e.target.value })} required style={style.inputField} />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={(e) => this.setState({ password: e.target.value })} required style={style.inputField} />
                    </div>
                    <div>
                        <label>Confirm Password:</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                            required
                            style={style.inputField}
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit" style={style.submitButton}>
                        Login
                    </button>
                </form>
                <LoadingOverlay show={showLoading} />
            </div>
        );
    }

    private _registerPlayer(): void {
        const { navigation } = this.props;
        const { username, password } = this.state;

        apiHelper.requestRegisterPlayer(username, password, (res: any) => {
            const response = JSON.parse(res);
            this.setState({ showLoading: false });

            if (response && response.status === 1) {
                switch (response.response.resultCode) {
                    case 'OK':
                        navigation!('/');
                        break;
                    case 'PLAYER_EXIST':
                        this.setState({ error: 'player already exist' });

                        break;
                    default:
                        this.setState({ error: `unknown error : ${res.resultCode}` });

                        break;
                }
            } else {
                this.setState({ error: `server error` });
            }
        });
    }
}

export default (props: RegisterProps) => {
    const navigation = useNavigate();

    return <RegisterPage {...props} navigation={navigation} />;
};
